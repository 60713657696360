import { getSettings, initialiseInternationalisation } from '@codeer/handypark-web-common';
import { Main, MainProvider, translations } from '@core';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import '@codeer/handypark-web-common/assets/fonts/fonts.scss';
import '@codeer/handypark-web-common/style.css';
import './styles.scss';

const rootElement = document.getElementById('root');

if (rootElement) {
  Promise.all([
    getSettings(),
    initialiseInternationalisation({
      resources: translations,
      debug: true,
    }),
  ])
    .then(() => {
      ReactDOM.createRoot(rootElement).render(
        <React.StrictMode>
          <BrowserRouter>
            <MainProvider>
              <Main />
            </MainProvider>
          </BrowserRouter>
        </React.StrictMode>,
      );
    })
    .catch(console.error);
} else {
  console.error('Root element not found');
}
