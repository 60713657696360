import { CountrySelectInputElementControl, CountrySelectInputElementOption } from '@/core';
import { Box, InputElementProps, InputLeftElement, useStyleConfig } from '@chakra-ui/react';
import { TCountryOption } from '@codeer/handypark-common';
import {
  AppSpinner,
  COLOR_SCHEMES,
  COMPONENT_NAMES,
  DATA_STATE,
  useGetFilteredCountries,
} from '@codeer/handypark-web-common';
import { FieldError } from 'react-hook-form';
import Select, { components, Props as SelectProps } from 'react-select';

import './country-select-input-element.style.scss';

type TCountrySelectInputElementProps = {
  value: string;
  onChange?: (countryOption: TCountryOption) => void;
  isDisabled?: boolean;
  inputElementProps?: InputElementProps;
  error?: FieldError;
};

const customSelectComponents = () => {
  /* --- COMPONENTS --- */
  const customComponents: Partial<typeof components> = {};

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  customComponents.Control = CountrySelectInputElementControl;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  customComponents.Option = CountrySelectInputElementOption;

  return customComponents;
};

/*
 * An extra element to an input field which shows a dropdown select with all countries
 * */
export const CountrySelectInputElement = ({
  value,
  onChange,
  isDisabled = false,
  inputElementProps,
  error,
}: TCountrySelectInputElementProps) => {
  /* --- STYLES --- */
  const selectStyles = useStyleConfig(COMPONENT_NAMES.CUSTOM_SELECT, {
    colorScheme: error ? COLOR_SCHEMES.WARNING : COLOR_SCHEMES.PRIMARY,
  });

  /* --- DATA --- */
  const { countriesToShowOptions, getFilteredCountriesDataState } = useGetFilteredCountries();
  const isGetFilteredCountriesLoading = getFilteredCountriesDataState === DATA_STATE.LOADING;

  const selectedOption: TCountryOption | undefined =
    countriesToShowOptions?.find(
      (countryOption: TCountryOption) => countryOption.value === value,
    ) ?? undefined;

  return (
    <InputLeftElement {...inputElementProps} sx={{ justifyContent: 'flex-start' }}>
      {isGetFilteredCountriesLoading ? (
        <Box ms={3} me={2}>
          <AppSpinner spinnerSize={'1.5rem'} showText={false} />
        </Box>
      ) : (
        <Box sx={{ ...selectStyles }} className={'react-select'}>
          <Select
            isDisabled={isDisabled}
            classNamePrefix={'react-select'}
            isLoading={isGetFilteredCountriesLoading}
            aria-busy={isGetFilteredCountriesLoading}
            options={countriesToShowOptions as SelectProps['options']}
            isClearable={false}
            isMulti={false}
            value={selectedOption}
            onChange={(v, _) => onChange && onChange(v as TCountryOption)}
            components={customSelectComponents()}
          />
        </Box>
      )}
    </InputLeftElement>
  );
};
